import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import { getForumDetail, getForumMessagePage } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { cn } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import collegeIcon from "@/assets/img/collegeIcon.png"
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { TextTypeEnum } from '@/enums/businessEnum';
import { useMount } from 'ahooks';
import { history, useModel } from '@umijs/max';
import BaseTopNav from '@/components/base/baseTopNav';
import { useTranslation } from 'react-i18next';
import RenderUtil from '@/utils/RenderUtil';
import PageEnum from '@/enums/pageEnum';

/**
 * pc 的文章
 */
export default () => {
  const [urlState] = useUrlState<any>({});
  const { t } = useTranslation();
  const { id } = urlState || {};
  const { isMobile } = useModel('system');
  // 选中的
  const [selected, setSelected] = useState<any>(id);
  const {
    run: getForumMessagePageReq,
    data: forumMessagePageData = {} as any,
    loading: forumMessagePageLoading,
  } = useReq(getForumMessagePage, {
    manual: true,
    onSuccess(data = {} as any) {
      setSelected(data?.list?.[0]?.id);
    },
  });
  useEffect(() => {
    getForumMessagePageReq({
      pageNo: 1,
      pageSize: 50,
    });
  }, []);

  //获取隐私协议和用户协议
  const {
    data: forumDetail = {} as any,
    run: getForumDetailReq,
    loading,
  } = useReq(getForumDetail, {
    manual: true,
  });

  useMount(() => {
    if (id) {
      setSelected(id);
    }
  });

  useEffect(() => {
    if (!isMobile) {
      getForumDetailReq({
        id: selected,
      });
    }

  }, [selected]);


  return (
    <>
      {
        isMobile && <div>
          <BaseTopNav title={t('学院')} />
          <BaseLoadingDisplay
            loading={forumMessagePageLoading}
            list={forumMessagePageData?.list || []}
          >
            {
              forumMessagePageData?.list?.map?.(item => {
                return <div key={item?.item} className="w-full px-4 flex py-2 items-center justify-between"
                            onClick={() => {
                              history.push(PageEnum.COLLEGE_DETAIL, { id:item?.id });
                            }}>
                  <div className="flex-1 overflow-hidden flex flex-col justify-between">
                    <div className="text-backContrastColor text-[14px] mb-2 max-w-full  line-clamp-3"
                         style={{ wordWrap: 'break-word' }}>{item?.messageTitle}</div>
                    <span className="text-auxiliaryTextColor text-xs">{RenderUtil.formatDate(item?.createTime)}</span>
                  </div>
                  <img src={item?.imgUrl ?? collegeIcon} className="flex-shrink-0 w-[86px] h-[48px] object-cover rounded-md"
                       alt="" />
                </div>;
              })
            }
          </BaseLoadingDisplay>
        </div>
      }
      {
        !isMobile && <div className="pt-3">
          <div className="flex space-x-2">
            <div className="w-[220px] h-[80vh] bg-backgroundAuxiliaryColor py-5 px-6 rounded-md overflow-y-auto ">
              <div>
                {forumMessagePageData?.list?.map((item: any, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelected(item.id)}
                      className={cn(
                        ' rounded-sm px-2.5 py-2 text-sm font-mono  mb-3 cursor-pointer',
                        {
                          'bg-pcPrimary': selected === item.id,
                          ' text-auxiliaryTextColor': selected !== item.id,
                        },
                      )}
                    >
                      {item?.messageTitle}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex-1 bg-backgroundAuxiliaryColor/50 rounded-md p-5">
              <div>
                <BaseLoadingDisplay
                  loading={loading}
                  list={loading ? [] : [forumDetail]}
                >
                  <div>
                    <FeaturesRenderText
                      data={{
                        content: forumDetail?.messageContent,
                        editorType: TextTypeEnum.MARKDOWN,
                      }}
                      className="bg-[transparent]"
                    />
                  </div>
                </BaseLoadingDisplay>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
